import logo from './logo.svg';
import './App.css';
import GivAcademy from './component/GivAcademy/GivAcademy';

function App() {
  return (
    <div className="App">
      <GivAcademy/>
    </div>
  );
}

export default App;
