import React, { useState, useRef } from 'react';
import './GivAcademy.css'
import logo from '../../assets/Group.svg'
import success from '../../assets/success .svg'
import emailjs from "@emailjs/browser";
import logo2 from '../../assets/BOK-signage-white.png'

const GivAcademy = () => {
  const form = useRef();
  const [formData, setFormData] = useState({
    firstname: "",
    lastname: "",
    phone: null,
    email: "",
    code: "",
  });
  const [currentStage, setCurrentStage] = useState(1);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const handleNextClick = () => {
    setCurrentStage(currentStage + 1);
  };
  const sendEmail = (e) => {
    console.log(form.current);
    e.preventDefault();
    // account cris
    // emailjs.sendForm('service_92e7aom', 'template_y0tw1fn', form.current, 'gBp7d8d39efNUd-0D')
    // account mohamad mokdad 
    // emailjs.sendForm('service_gwzxmi6', 'template_oxijh28', form.current, '3G6BMiFHLBBiI_fm5')
    // account hala nassif 
    emailjs.sendForm('service_in1h34w', 'template_ms2krhu', form.current, 'e8vn-txeHV5Fn_8D5')
      // demo account 
      // emailjs.sendForm('service_ouhsdfoguhsdfg92e7aom', 'templaoausdfhgiouhte_y0tw1fn', form.current, 'gBp7d8d39efNUdksgbiou;dafshguhdfogs-0D')
      .then((result) => {
        console.log(result.text);
        handleNextClick();
      }, (error) => {
        alert("try again later" + error.text);
        console.log(error.text);
      });
  };
  return (
    <div className='GivSendEmail-container-bg'>
      {currentStage === 1 && (
        <form onSubmit={sendEmail} className='w-auto' ref={form}   >
          <div className='GivSendEmail-container'>
            <div className="logo-container">
              <div className="GivSendEmail-logo"><img src={logo} alt="" /></div>
              <div><img className="GivSendEmail-logo-1" src={logo2} alt="" /></div>
            </div>
            <div className="GivSendEmail-title">Interested in a comprehensive 40 hours training program in forex and trading, Certified  by Paris business school? </div>
            <div className="GivSendEmail-subtitle">Drop your information and let's get started</div>
            <div className="form-group">
              <input name="firstname" type="text" className="form-control" placeholder="First Name" value={formData.firstname} onChange={handleChange} required autoComplete='off'
              />
            </div>
            <div className="form-group">
              <input name="lastname" type="text" className="form-control" placeholder="Last Name" value={formData.lastname} onChange={handleChange} required autoComplete='off' />
            </div>
            <div className="form-group">
              <input name="phone" type="tel" className="form-control" placeholder="Phone" value={formData.phone} onChange={handleChange} required autoComplete='off' />
            </div>
            <div className="form-group">
              <input name="email" type="email" className="form-control" placeholder="Email" value={formData.email} onChange={handleChange} required autoComplete='off' />
            </div>
            <br />
            <div className="GivSendEmail-subtitle-1"><p>Get <span className='GivSendEmail-subtitle-1-span'> 20% discount</span> by using our code ”GIV20”</p></div>
            <div className="promo-code">
              <div className="form-group">
                <input name="code" type="text" className="form-control-1" placeholder="PROMO CODE" value={formData.code} onChange={handleChange} autoComplete='off' />
              </div>
              <button className='GivSendEmail-btn-1' type='submit'>APPLY NOW</button>
            </div>
          </div>
        </form>
      )}
      {currentStage === 2 && (
        <div className='GivSendEmail-container-2'>
          <div className="GivSendEmail-logo"><img src={success} alt="" /></div>
          <div className="GivSendEmail-title-2">Congrats on your 20% off.</div>
          <div className='GivSendEmail-title-1-container'>
            <div className="GivSendEmail-title-1">Tuition fees </div>&nbsp;&nbsp;
            <div className='mmm'>
              <div className="GivSendEmail-title-1-box1"> $1200 </div>&nbsp;&nbsp;
              <div className="GivSendEmail-title-1-svg"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 25"><path fill='white' d="m17.5 5.999-.707.707 5.293 5.293H1v1h21.086l-5.294 5.295.707.707L24 12.499l-6.5-6.5z" data-name="Right" /></svg></div>&nbsp;&nbsp;
              <div className="GivSendEmail-title-1-box2"> $960 </div>
            </div>
          </div>                                 
          <div className="GivSendEmail-title-2"> We will contact you within <span className='GivSendEmail-title-2-span'>24&nbsp;hours</span></div>
          <div className="GivSendEmail-logo"><img src={logo} alt="" /></div>
        </div>
      )}
    </div>
  );
}

export default GivAcademy;